import imageInput from '@/mixins/image-input.js';
import {mapState} from 'vuex';
import NewsPostModel from '../news/models/news-post.model';

export default {
    data() {
        return {
            item: {
                active: false
            }
        }
    },
    methods: {
        approve() {
            let data = new NewsPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("news/setItem", formData).then((res) => {
                if (res) {
                    this.item = {
                        active: false
                    };
                    this.resfresh = true;
                }
            });
        }
    },
    computed: {
        ...mapState({
            categories: (state) => state.categories.items,
        }),
    },
    mixins: [imageInput],
}
